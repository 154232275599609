$( document ).ready(function() {

  Initialize();

  /* question counters */
  var qCount = 0;
  var qA = 0;
  var qB = 0;
  var qC = 0;
  /* background animation stagings */
  $("#start-quiz").click(function(e) {
    /* move backgrounds and show intro clip */
    $(".u-bg-slide").removeClass("slide-out-r slide-right");
    $(".u-bg-slide").addClass("slide-center");
    $(".o-intro__main").addClass("u-hidden");
    $(".u-intro-slide").addClass("fade-out");
    ChangeVideoSrc('jr-video','jr-intro');

    /* once video ended go to question setup and display video */
    $('#jr-video-holder').on('ended',function(){
      if (qCount < 1) {
        $(".u-bg-slide").removeClass("slide-center slide-right");
        $(".u-bg-slide").addClass("slide-out-r");
        $(".u-question-slide").removeClass("slide-out-l");

        /* go to question setup and display video */
        setTimeout(function(){
           $(".c-video-bg").removeClass("shy-container");
        }, 800);
        QuestionIn(0);
      }
    });
  });



  /* question mechanics */
  $(".o-question__item").click(function(e) {
    // results raw, to optimize
    var qType= $(e.target).attr( "data-type");
    if (qType == 'qA'){
      qA++;
    }if (qType == 'qB'){
      qB++;
    }if (qType == 'qC'){
      qC++;
    }if (qType == 'qD'){
      qD++;
    }
    qCount++;

    QuestionOut(qCount);
    if (qCount < 5) {
      QuestionIn(qCount);
    }

    else if (qCount == 5) {
      ShowResults(qA,qB,qC);
    }
    console.log(qCount);

  });

  $('#qa-video-play').click(function(){
    $('#qa-video-play').hide();
    $('#qa-video-holder')[0].play();
  });


  $(".js-toggle-video").click(function(e) {
    var qActive = qCount+1;
    if ($("#question-"+qActive).hasClass("slide-in")){
      $("#question-"+qActive).removeClass("slide-in");
      if ($('#qa-video-holder')[0].currentTime == 0){
        $('#qa-video-holder')[0].play();
      }
      $(e.target).text('Skip Video'); 
      $(e.target).removeClass('toggled'); 
    }else {
      $("#question-"+qActive).addClass("slide-in");
      setTimeout(function(){
        $("#question-"+qActive).find(".q-element-lhand").addClass("slide-out");
        setTimeout(function(){
          $("#question-"+qActive).find(".q-element-lhand").addClass("disappear");
        }, 800);
      }, 600);
      $(e.target).text('watch video');
      $(e.target).addClass('toggled'); 
    }
  });
  
});




function Initialize() {
  $(".js-answer-shuffle").shuffleQuestions();
  $(".c-title").addClass("fade-in");
  setTimeout(function(){
    $(".black-board").addClass("black-board--visible");
  }, 200);
}

/* question out actions*/
$.fn.shuffleQuestions = function() {
    $.each(this.get(), function(index, el) {
        var $el = $(el);
        var $find = $el.children();
        $find.sort(function() {
            return 0.5 - Math.random();
        });
        $el.empty();
        $find.appendTo($el);
    });
};

/* question out actions*/
function QuestionOut(qindex) {
  // hammer animation
  $("#question-"+qindex).find(".q-element-lhand").addClass("disappear");
  $(".q-element-hammer").addClass("hit-hammer");
  $("#audio-hammer")[0].play();
  $('#qa-video-holder')[0].pause();
  $(".c-video-bg").addClass("shy-container");
  // out question animation
  $("#question-"+qindex).removeClass("slide-in");
  $("#question-"+qindex).addClass("slide-out");
  setTimeout(function(){
    $("#question-"+qindex).addClass("finished");
  }, 500);
}

/* question in actions */
function QuestionIn(qindex) {
  setTimeout(function(){
    var qNext = qindex+1;
    $(".c-video-bg").removeClass("shy-container");
    
    ChangeVideoSrc('qa-video', "m-question-"+qNext)
    $(".q-element-hammer").removeClass("hit-hammer");
    $("#question-"+qNext).addClass("pick-in");
    $('#qa-video-holder').on('ended',function(){
      $('#qa-video-holder')[0].currentTime = 0;
      $("#question-"+qNext).addClass("slide-in");
      $("#question-"+qNext).find(".js-toggle-video").text('replay video');
      $("#question-"+qNext).find(".js-toggle-video").addClass('toggled');
      setTimeout(function(){
        $("#question-"+qNext).find(".q-element-lhand").addClass("slide-out");
        setTimeout(function(){
          $("#question-"+qNext).find(".q-element-lhand").addClass("disappear");
        }, 800);
      }, 600);
    });
  }, 700);
}

/* show results actions */
function ShowResults(qA,qB,qC) {
  var scores = [qA, qB, qC];
  var result = scores.indexOf(Math.max(qA, qB, qC));
  setTimeout(function(){
  $(".u-question-slide").addClass("slide-out-l");
  $(".u-result-slide").removeClass("slide-out-l");
  $(".u-bg-slide").removeClass("slide-out-r slide-center");
  $(".u-result-slide").addClass("slide-in");
  $(".o-score__share").addClass("slide-right");
  setTimeout(function(){
    $(".o-score__share").addClass("appear");
  }, 800);
  $(".u-bg-slide").addClass("slide-right");
    setTimeout(function(){
       $(".o-score").addClass("slide-in");
       switch (result) {
         case 0:
           ChangeVideoSrc('jr-video','jr-A-score');
           $('#score-A').removeClass('u-hidden');
           $('#result-A').removeClass('u-hidden');
           break;
         case 1:
           ChangeVideoSrc('jr-video','jr-B-score');
           $('#score-B').removeClass('u-hidden');
           $('#result-B').removeClass('u-hidden');
           break;
         default:
           ChangeVideoSrc('jr-video','jr-C-score');
           $('#score-C').removeClass('u-hidden');
           $('#result-C').removeClass('u-hidden');
           break;
       }
    }, 1000);
  }, 500);
 
}

/* change videos src*/
function ChangeVideoSrc(video,source) {
  var videoID = video+'-holder';
  var sourceID = source+'-assets';
  $('#'+videoID).html(
    $('#'+sourceID).html()
  );
  $('#'+videoID)[0].load();
  
  var promise = $('#'+videoID)[0].play();
  if (promise !== undefined) {
    promise.catch(error => {
      // Auto-play was prevented
      // Show a UI element to let the user manually start playback
      $('#qa-video-play').show();
    }).then(() => {
      // Auto-play started
    });
  }
}